<script setup lang="ts">
</script>
<template>
  <div
    class="main"
    id="main"
  >
    <img
      class="main-bg--image web"
      src="@/assets/images/svg/blue-web.svg"
      alt="">
    <img
      class="main-bg--image box"
      src="@/assets/images/svg/empty-box.svg"
      alt="">
    <img
      class="main-bg--image line"
      src="@/assets/images/svg/line-1.svg"
      alt="">
    <div
      class="nav-container"
    >
      <div class="main-text">
        <h1 class="main-text-title">Placement of targeted <br>advertising on social <br>network</h1>
        <h2 class="main-text-subtitle">We aim to increase your sales</h2>
      </div>
      <div class="main-right">
        <img
          class="main-right-image"
          src="@/assets/images/main-sec-img.webp"
          alt="">
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: get-vw-pc(1854px);
  margin-left: auto;
  margin-right: auto;
  &.tablet {
    width: 95%;
  }
}
.main {
  //height: get-vw-pc(158px);
  //background: #000000;
  padding-top: get-vw-pc(42px);
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: get-vw-pc(150px);
  &-bg--image {
    position: absolute;
    &.web {
      top: get-vw-pc(-40px);
      left: get-vw-pc(-270px);
      width: get-vw-pc(384.21px);
      height: get-vw-pc(361.19px);
    }
    &.box {
      top: get-vw-pc(190px);
      left: get-vw-pc(5px);
      width: get-vw-pc(186.89px);
      height: get-vw-pc(183.83px);
    }
    &.line {
      top: get-vw-pc(-70px);
      left: get-vw-pc(-170px);
      width: get-vw-pc(2815px);
      height: get-vw-pc(1005px);
    }
  }
  &-text {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-family: 'Inter';
    font-style: normal;
    color: #FFFFFF;
    width: get-vw-pc(925px) !important;
    &-title {
      font-weight: 800;
      font-size: get-vw-pc(64px);
      text-transform: uppercase;
      line-height: 117.15%;
    }
    &-subtitle {
      font-weight: 700;
      font-size: get-vw-pc(36px);
      text-transform: capitalize;
      line-height: 117.15%;
      margin-top: get-vw-pc(94px);
    }
  }
  &-right {
    width: get-vw-pc(925px) !important;
    position: relative;
    &-image {
      position: relative;
      flex: 1;
      width: get-vw-pc(1205px);
      height: get-vw-pc(1089px);
      margin-top: get-vw-pc(-270px);
      left: get-vw-pc(-125px);
    }
  }
}
</style>
